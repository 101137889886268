import {Component, OnInit} from '@angular/core';
import {AuthService} from './login/auth.service';
import {UsuarioLogado} from './model/usuario.model';
import {AlertService} from "ngx-alerts";

@Component({
    selector: 'rdft-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    title = 'rdft';
    usuarioLogado: UsuarioLogado;

    constructor(private auth: AuthService,
                private alertService: AlertService) {
    }

    ngOnInit() {
        this.auth.usuarioSessao.subscribe(us => this.usuarioLogado = us);
        this.auth.mensagemW_.subscribe(m => {
            if (m && m.length > 0) {
                this.alertService.warning(m);
            }
        });
        this.auth.mensagemD_.subscribe(m => {
            if (m && m.length > 0) {
                this.alertService.danger(m);
            }
        });
        this.auth.mensagemI_.subscribe(m => {
            if (m && m.length > 0) {
                this.alertService.info(m);
            }
        });
    }
}
