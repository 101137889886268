import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_BASE, Error} from '../app.api';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CceService {

    constructor(private http: HttpClient) {
    }

    public buscarContribuinte(identificador: string): Observable<any> {
        return this.http.get(`${API_BASE}/cce/${identificador}`).pipe(
            catchError(Error.error)
        );
    }
}
