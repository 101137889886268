import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { API_BASE, Error, ResponseModel } from '../app.api';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProcessoService {

  private processo: any;
  processoObservavel = new BehaviorSubject(this.processo);
  processo_ = this.processoObservavel.asObservable();

  private documentos: any[];
  documentosObservavel = new BehaviorSubject(this.documentos);
  documentos_ = this.documentosObservavel.asObservable();

  private documento: any;
  documentoObservavel = new BehaviorSubject(this.documento);
  documento_ = this.documentoObservavel.asObservable();

  constructor(private http: HttpClient) {
  }

  public buscaProcessoPorNumero(numero: string): Observable<any> {
    return this.http.get<ResponseModel>(API_BASE + '/processo/' + numero).pipe(
      catchError(Error.error)
    );
  }

  public buscaProcessoPorUsuario(login: string): Observable<any> {
    return this.http.get<ResponseModel>(API_BASE + '/processo/login/' + login).pipe(
      catchError(Error.error)
    );
  }

  clearProcesso() {
    this.processoObservavel.next(null);
    this.documentoObservavel.next(null);
    this.documentosObservavel.next(null);
  }

}
