import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from './auth.service';

@Component({
  selector: 'rdft-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  statusLoad = 'Iniciando Sessão...';

  constructor(private activatedRouter: ActivatedRoute, private router: Router, private auth: AuthService) {
  }

  ngOnInit() {
    this.activatedRouter.queryParams.subscribe(param => {
      const txt = param['id'];
     //const txt = 'VVNSPWttaWt1cmkmSVA9MTAuNS44MS4xMjkmTUFUPTI0JkdSVT05MDAmVU5JPTE2NCZTSVM9MTI3Jk1PRD0tMSZOSVY9MDAwMDAwMDAwMDAwJkRUSFI9MTcvMDIvMjAyMiAxNDo0NzoxNQ%3D%3D ';
      const rota = param['aqs'];
      if (txt) {
        this.auth.login(txt, rota);
      }
    });
  }

}
