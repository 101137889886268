import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './login/login.component';
import {AuthService} from './login/auth.service';
import {HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';
import {PipesModule} from './pipes/PipesModule';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {SidebarQuickComponent} from './sidebar-quick/sidebar-quick.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AmChartsModule, AmChartsService} from '@amcharts/amcharts3-angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {FormsModule} from '@angular/forms';
import {FileUploadModule} from 'ng2-file-upload';
import {NgxMaskModule} from 'ngx-mask';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AlertModule, AlertService} from 'ngx-alerts';
import {LoadingModule} from 'ngx-loading';
import {CKEditorModule} from 'ng2-ckeditor';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {PDFExportModule} from '@progress/kendo-angular-pdf-export';
import {UiSwitchModule} from 'ngx-ui-switch';
import {Ng2OrderModule} from 'ng2-order-pipe';


registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        SidebarQuickComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AmChartsModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        FormsModule,
        FileUploadModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        AlertModule.forRoot({maxMessages: 5, timeout: 5000}),
        LoadingModule,
        CKEditorModule,
        CurrencyMaskModule,
        PipesModule,
        PDFExportModule,
        HttpClientModule,
        UiSwitchModule.forRoot({
            color: '#32c5d2',
            switchColor: '#fff',
            defaultBgColor: '#cccccc',
            defaultBoColor: '#fff',
        }),
        Ng2OrderModule
    ],
    providers: [
        AlertService,
        AmChartsService,
        AuthService,
        {provide: LOCALE_ID, useValue: 'pt'},
        HttpConfigInterceptor, {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
