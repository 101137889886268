import { Component, OnInit } from '@angular/core';
import { AuthService } from '../login/auth.service';
import { Router } from '@angular/router';
import { User, UsuarioLogado } from '../model/usuario.model';

@Component({
  selector: 'rdft-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  usuarioDeveloper: User;
  usuarioLogado: UsuarioLogado;
  developer = false;

  constructor(private router: Router,
    private auth: AuthService) {
  }

  ngOnInit() {

    this.auth.usuarioSessao.subscribe(us => {
      if (us) {
        this.usuarioLogado = us;
      }
    })

    this.auth.usuarioDeveloper.subscribe(us => {
      if (us) {
        this.usuarioDeveloper = us;
      }

    })
  }

  toggleCollapsed() {
    if (this.developer) {
      const dom: any = document.querySelector('body');
      dom.classList.toggle('page-quick-sidebar-open');
    }
  }

  processLoaded() {
    return true;
  }

}
