import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_AUTH, API_BASE, Error, ResponseModel } from '../app.api';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User, UsuarioLogado } from '../model/usuario.model';
import { CceService } from "../service/cce.service";
import { UsuarioService } from '../service/usuario.service';


@Injectable()
export class AuthService {

  public usuarioLogado = false;

  private usuario: UsuarioLogado;
  usuarioObservable = new BehaviorSubject(this.usuario);
  usuarioSessao = this.usuarioObservable.asObservable();

  private usuarioDetalhe: any;
  usuarioDetalheObservable = new BehaviorSubject(this.usuarioDetalhe);
  usuarioDetalhe_ = this.usuarioDetalheObservable.asObservable();

  private dataSistema = new Date;
  dataPublicacaoObservable = new BehaviorSubject(this.dataSistema);
  dataPublicacao = this.dataPublicacaoObservable.asObservable();

  private versao = '';
  versaoObservable = new BehaviorSubject(this.versao);
  versaoSistema = this.versaoObservable.asObservable();

  private profile = '';
  profileObservable = new BehaviorSubject(this.profile);
  profileSistema = this.profileObservable.asObservable();

  private txt = '';
  txtObservable = new BehaviorSubject(this.txt);
  txt_ = this.txtObservable.asObservable();

  public tipoLogin = '';
  tipoLoginObservable = new BehaviorSubject(this.tipoLogin);
  tipoLogin_ = this.tipoLoginObservable.asObservable();

  public mudarIE = '';
  mudarIEObservable = new BehaviorSubject(this.mudarIE);
  mudarIE_ = this.mudarIEObservable.asObservable();

  private mensagemW: string;
  mensagemWObservable = new BehaviorSubject(this.mensagemW);
  mensagemW_ = this.mensagemWObservable.asObservable();

  private mensagemD: string;
  mensagemDObservable = new BehaviorSubject(this.mensagemD);
  mensagemD_ = this.mensagemDObservable.asObservable();

  private mensagemI: string;
  mensagemIObservable = new BehaviorSubject(this.mensagemI);
  mensagemI_ = this.mensagemIObservable.asObservable();

  usuarioDeveloperObservable = new BehaviorSubject(null);
  usuarioDeveloper = this.usuarioDeveloperObservable.asObservable();
  usuariosDevs: UsuarioLogado[] = [
    {
      user: {
        id: 1,
        userName: 'gmaidana',
        matricula: '',
        password: '',
        grupo: 1157,
        nome: '',
        unidade: 1,
      },
      token: '46db5b39cafb8df52b5a08d8faf5fb985fd5bb9751bf874375af4a1264fe0129'
    },
    {
      user: {
        id: 1,
        userName: 'fcsouza',
        matricula: '',
        password: '',
        grupo: 1157,
        nome: '',
        unidade: 1,
      },
      token: '3ca5462995013c26affc9e477dd073100c5181edc01af525ee682243106337ea'
    },
    {
      user: {
        id: 1,
        userName: 'kmikuri',
        matricula: '',
        password: '',
        grupo: 1157,
        nome: '',
        unidade: 1,
      },
      token: '01e3d046cfaea01a5ffd64528b716949c906ac26423ba5011f5c5d69f6200db6'
    },
    {
      user: {
        id: 1,
        userName: 'mmcavanha',
        matricula: '',
        password: '',
        grupo: 1157,
        nome: '',
        unidade: 1,
      },
      token: '93064f137f643c5b78b927d46e26e2e214c8d326db1d9cf8a4ebaf0590f1b43f'
    },
    {
      user: {
        id: 1,
        userName: 'rsimioli',
        matricula: '',
        password: '',
        grupo: 1157,
        nome: '',
        unidade: 1,
      },
      token: '0aebc99e8b350457975d92e40793e018bc9e82452beb91f753097c6ac2305ed7'
    },
    {
      user: {
        id: 1,
        userName: 'wlemes',
        matricula: '',
        password: '',
        grupo: 1157,
        nome: '',
        unidade: 1,
      },
      token: 'e7bc3e8b50b42555abfd319b89906d7d6f1565c550b5aa4d7f8e6523d7d89930'
    },
    {
      user: {
        id: 1,
        userName: 'fahad',
        matricula: '',
        password: '',
        grupo: 1157,
        nome: '',
        unidade: 1,
      },
      token: 'abc4c0753f0e7af132a2e2ddcb2a77066701317dfcd0160c966357ea06765937'
    },
    {
      user: {
        id: 1,
        userName: '283953853',
        matricula: '',
        password: '',
        grupo: 1156,
        nome: '',
        unidade: 191,
      },
      token: '132bc518c39b37119b5be6d0de4334fc97713a2552fc2b181f04ce237a74ac72'
    }

  ];

  constructor(private http: HttpClient,
    private router: Router,
    private cceService: CceService,
    private usuarioService: UsuarioService) {
  }


  public login(txt: string, rota: string) {
    const userDev = this.usuariosDevs.filter((element) => element.token === txt);
    if (userDev.length > 0 && window.location.host !== 'www.erudfto.ms.gov.br') {
      console.log(userDev);
      this.txtObservable.next(txt);
      this.setUsuarioLogado(userDev[0]);
      localStorage.setItem("login", userDev[0].user.userName);
      //this.router.navigate(rota ? [`/${rota}`] : ['/dashboard']);
    }
    else {
      this.autenticar(txt).subscribe(retorno => {
        if (retorno.usuario) {
          console.log("teste retorno.usuario", retorno.usuario);
          const user: User = {
            id: retorno.usuario.id,
            userName: retorno.usuario.loginAD,
            matricula: retorno.usuario.matricula,
            password: null,
            unidade: retorno.usuario.unidadeID,
            nome: retorno.usuario.nome,
            grupo: retorno.usuario.grupoID
          };
          localStorage.setItem("login", retorno.usuario.loginAD);
          const userLogado: UsuarioLogado = {
            user: user,
            token: txt
          };
          this.txtObservable.next(txt);
          this.setUsuarioLogado(userLogado);
          this.usuarioLogado = true;
        } else {
          console.log(retorno.status);
          this.login(txt, rota);
        }
      });
    }
  }

  setUsuarioLogado(usuario: UsuarioLogado) {
    this.usuarioObservable.next(usuario);
    this.usuarioSessao.subscribe(us => {
      if (us && us.user) {
        this.usuarioLogado = true;
        if (us.user.userName.replace(/[^0-9]/g, '').length > 0) {
          this.cceService.buscarContribuinte(us.user.userName).subscribe(cce => {
            if (cce.messages[0].code === 200) {
              this.usuarioDetalheObservable.next(cce.data[0].contribuinte);

            } else {
              console.log(`${cce.messages[0].code} - ${cce.messages[0].message}`);
            }
          });
        }
        this.router.navigate(['/menu']);
        this.dataPublicacaoSistema().subscribe(dataVersao => {
          this.dataPublicacaoObservable.next(dataVersao.data.dataPublicacao);
          this.versaoObservable.next(dataVersao.data.versao);
          this.profileObservable.next(dataVersao.data.profile);
        });
      }
    });
  }


  private autenticar(txt: string): Observable<any> {
    return this.http.get<ResponseModel>(API_AUTH + '/authenticate/' + txt).pipe(
      catchError(Error.error)
    );
  }


  private dataPublicacaoSistema(): Observable<any> {
    return this.http.get<ResponseModel>(API_BASE + '/system/dataVersao').pipe(
      catchError(Error.error)
    );
  }
}
