import { Injectable } from '@angular/core';
import { AuthService } from '../login/auth.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { API_BASE, Error, ResponseModel } from '../app.api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PathService {

  constructor(private authService: AuthService, private http: HttpClient) {
  }

  getRepositorioPath(url: string): string {
    switch (this.authService.profileObservable.getValue()) {
      case 'Development':
      case 'Homologation':
      case 'dev':
      case 'hom':
      case 'local':
        return `http://172.20.12.140/rdft-repositorio/v1/${url}`;
      case 'Production':
      case 'prd':
        return `http://www.epat.ms.gov.br/rdft-repositorio/v1/${url}`;
      default:
        return null;
    }
  }
  public GerarToken(): Observable<any> {
    return this.http.get<ResponseModel>(API_BASE + '/Token').pipe(
      catchError(Error.error)
    );
  }
}
