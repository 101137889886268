import { User } from "./usuario.model";

export class Documento {
  id?: number;
  numero?: string;
  protocolo?: string;
  dataCriacao?: Date;
  numeroProcesso?: string;
  chaveCategoria?: string;
  categoria?: CategoriaDocumento;
  usuarioCriacao?: User;
  anexos?: ComponenteDigital[];
  titulo?: string;
  situacao?: string;
  status?: string;
  processo?: string;
  dataPublicacao?: string;
  usuarioPublicacao?: User;
  versao?: string;
  tipoMeio?: string;
  detalhes?: any;
  componenteDigital?: ComponenteDigital;
  arquivoProtocolo?: ComponenteDigital;
  dataProtocolo?: string;
}
export class ComponenteDigital {
  chave: string;
  versao?: string;
  formato: string;
  nomeOriginal: string;
  tamanho: string;
  md5: string;
  base64?: string;
  tipo: TipoComponenteDigital;
  pagInicial: number;
  pagFinal: number;
  caminhoArquivo: string;
  quantidadePaginas: number;
}

export class TipoComponenteDigital {
  id: number;
  descricao: string;
}

export interface ConfirmacaoDocumentoDTO {
  id: number;
  protocolo: string;
}

export class ProtocoloEntrega {
  protocolo: string;
  numeroProcesso: string;
  numeroDocumento: string;
  chaveCategoriaDoc: string;
  nomeAgente: string;
  telefoneAgente: string;
  emailAgente: string;
  tituloDocumento: string;
  componentesDigitais: ComponenteDigital[];
  caminhoArquivo: string = "";
  versao: number;
}

export class Protocolo {

  id: number;
  numeroProcesso: string;
  numeroDocumento: string;
  protocoloDeEntrega: string;
  chaveCategoria: string;
  dataReserva: string;
  status: string;
  loginUsuario: string;

  constructor(login: string) {
    this.loginUsuario = login;
  }
}

export class CategoriaDocumento {
  id: number;
  nome: string;
  classe: string;
  titulo: string;
  modelo: ComponenteDigital;
  tiposComponentesDigitais: TipoComponenteDigital[];
  assinatura: boolean;
  minuta: boolean;
  fiscal: boolean;
  contribuinte: boolean;
  cancelaDoc: boolean;
  unico: boolean;
  texto: string;
  cancelamento: any;
  desabilitaEvento = false;
}
