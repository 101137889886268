import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { VersionamentoArquivo } from "../model/versionamento-arquivo.model";

@Injectable({
    providedIn: "root",
})
export class VersionamentoArquivosService {
    private versionamentoArquivos$ = new BehaviorSubject(
        new VersionamentoArquivo()
    );

    constructor() {}

    getVersionamentoArquivos(): Observable<VersionamentoArquivo> {
        return this.versionamentoArquivos$.asObservable();
    }
    setVersionamentoArquivos(versionamentoArquivos: VersionamentoArquivo) {
        this.versionamentoArquivos$.next(versionamentoArquivos);
    }
    incrementarVersao(){
        let versionamento = new VersionamentoArquivo();
        versionamento.caminhoArquivo = this.versionamentoArquivos$.getValue().caminhoArquivo;
        versionamento.versaoProtocolo =this.versionamentoArquivos$.getValue().versaoProtocolo;
        versionamento.versaoProtocolo = versionamento.versaoProtocolo + 0.1;
        console.log("Service + ", versionamento)
        this.versionamentoArquivos$.next(versionamento)
    }
}
