import { Component, OnInit } from '@angular/core';
import { AuthService } from '../login/auth.service';
import { UsuarioLogado } from '../model/usuario.model';

@Component({
  selector: 'rdft-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  usuarioLogado: UsuarioLogado;
  dataPublicacao: Date;
  versao: string;
  ano: string;

  constructor(private auth: AuthService) {
  }

  ngOnInit() {
    this.auth.usuarioSessao.subscribe(us => this.usuarioLogado = us);
    this.auth.dataPublicacao.subscribe(dt => this.dataPublicacao = dt);
    this.auth.versaoObservable.subscribe(vs => this.versao = vs);
    this.ano = new Date().getFullYear().toString();
  }

}
