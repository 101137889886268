import {Component, OnInit} from '@angular/core';
import {AuthService} from '../login/auth.service';
import {UsuarioLogado} from '../model/usuario.model';
import {ProcessoService} from "../service/processo.service";
import {saveAs} from "@progress/kendo-drawing/pdf";
import {FileService} from "../service/file.service";

@Component({
    selector: 'rdft-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

    usuarioLogado: UsuarioLogado;
    atividadeAtual;
    processo: any;
    documentos: any[];
    documento: any;

    constructor(private auth: AuthService,
                private processoService: ProcessoService,
                private fileService: FileService) {
    }

    ngOnInit() {
        this.auth.usuarioSessao.subscribe(us => {
            this.usuarioLogado = us;
        });
        this.processoService.processo_.subscribe(pc => {
            this.processo = pc;
        });
        this.processoService.documentos_.subscribe(docs => {
            if (docs) {
                this.documentos = docs;
            }
        });
    }

    usuarioSessao(): boolean {
        return !!(this.usuarioLogado && this.usuarioLogado.user);
    }

    baixarArquivo(chave: string, filename: string) {
       // this.fileService.baixarArquivoPorChave(chave).subscribe(retorno =>
        this.fileService.baixarArquivoCaminho(chave).subscribe(retorno =>
            saveAs(retorno, filename)
        );
    }
}
