import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_BASE, Error, ResponseModel } from '../app.api';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { };


  public buscarUsuarioDetalhado(login: string): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.get<ResponseModel>(`${API_BASE}/usuarios/buscarporlogin/${login}`).pipe(
      catchError(Error.error)
    );
  }


}
