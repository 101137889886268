import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_BASE, Error, ResponseModel } from '../app.api';
import { AuthService } from '../login/auth.service';
import { CategoriaDocumento, ComponenteDigital, Documento, Protocolo, ProtocoloEntrega } from '../model/documentos.model';
import { UsuarioLogado } from '../model/usuario.model';
import { VersionamentoArquivo } from '../model/versionamento-arquivo.model';
import { PathService } from './path.service';
import { VersionamentoArquivosService } from './versionamento-arquivos.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  documento: any = {};
  print: ElementRef;
  assinando: boolean;
  assinandoObservavel = new BehaviorSubject(this.assinando);
  assinando_ = this.assinandoObservavel.asObservable();
  componentes: ComponenteDigital[] = [];
  componentesObservable = new BehaviorSubject(this.componentes);
  componentes_ = this.componentesObservable.asObservable();

  //private documentoOriginario$ = new BehaviorSubject(this.documento);
  private documentoOriginario$ = new BehaviorSubject(new Documento);

  categoria: CategoriaDocumento;
  categoriaObservable = new BehaviorSubject(this.categoria);
  categoria_ = this.categoriaObservable.asObservable();

  constructor(private httpClient: HttpClient,
    private pathService: PathService,
    private authService: AuthService,
    private versionamentoArquivoService: VersionamentoArquivosService) {
  }


  getDocumentoOriginario(): Observable<Documento> {
    return this.documentoOriginario$.asObservable();
  }
  setDocumentoOriginario(value:any, numeroProcesso: string) {
    if (value || numeroProcesso) {
      var versiona = new VersionamentoArquivo();
      console.log("-------")
      console.log("setDocumentoOriginarioVALUE ", value)
      console.log("-------")
      console.log("setDocumentoOriginario versiona  ", versiona)
      versiona.versaoProtocolo = 1
      if (value && value.interessados.length> 0 && value.interessados[0].identificador) {
        versiona.caminhoArquivo = value.interessados[0].identificador + "/";
      } else {
        versiona.caminhoArquivo = numeroProcesso + "/";

      }
      if (value && value.dataCriacao) {
        var data = new Date(value.dataCriacao)
        versiona.caminhoArquivo += data.getFullYear() + "/" + data.getMonth();
      } else {
        var data = new Date()
        versiona.caminhoArquivo += data.getFullYear() + "/" + data.getMonth();
      }
      const str = new Date();
      let [dia, mes, ano, hora, minuto, segundo] = str.toLocaleString('pt-BR').split(/[\/ :]/).map(v => parseInt(v));
      const dataNow = new Date(Date.UTC(ano, mes - 1, dia, hora, minuto, segundo));
      console.log(dataNow.toString())
      versiona.caminhoArquivo += "/" + dataNow.toISOString().replace('T', '').substring(0, 18).replace(new RegExp("-", "g"), "").replace(new RegExp(":", "g"), "") + str.getMilliseconds().toString()
    }
    console.log(versiona);
    this.versionamentoArquivoService.setVersionamentoArquivos(versiona)

    this.documentoOriginario$.next(value);
  }

  public gerarProtocoloEntrega(componentes: ComponenteDigital[], numeroOficial?: string, versionamentoArquivo?: VersionamentoArquivo): Observable<any> {
    const protocolo = new ProtocoloEntrega();
    //protocolo.chaveCategoriaDoc = this.documento.chaveCategoria.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    protocolo.chaveCategoriaDoc = this.documento.chaveCategoria.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    var documentoOriginario = new VersionamentoArquivo();
    console.log("VersionamentoARQUIVO ", versionamentoArquivo)
    this.versionamentoArquivoService.getVersionamentoArquivos().subscribe(value => {
      console.log(value)
      documentoOriginario.caminhoArquivo = value.caminhoArquivo;
      documentoOriginario.versaoProtocolo + value.versaoProtocolo;

    });
    const arquivos = [];
    arquivos.push(this.documento.componenteDigital);
    if (componentes) {
      componentes.forEach(comp => {

        arquivos.push(comp);
      });
    }
    protocolo.componentesDigitais = arquivos;
    if (this.authService.usuarioDetalheObservable.getValue()) {
      const us = this.authService.usuarioDetalheObservable.getValue();
      protocolo.emailAgente = us.pessoa.email;
      protocolo.nomeAgente = `${this.ie(us.inscricaoEstadual)} - ${us.pessoa.nome}`;
      if (us.pessoa.telefone2_Comercial) {
        protocolo.telefoneAgente = us.pessoa.telefone2_Comercial;
      } else if (us.pessoa.telefone1_Residencial) {
        protocolo.telefoneAgente = us.pessoa.telefone1_Residencial;
      }
    } else {
      const us = this.authService.usuarioObservable.getValue();
      //protocolo.nomeAgente = us.user.userName;
      protocolo.nomeAgente = localStorage.getItem("login");
    }
    protocolo.numeroDocumento = numeroOficial ? numeroOficial : this.documento.numero;
    protocolo.protocolo = this.documento.protocolo;
    protocolo.numeroProcesso = this.documento.numeroProcesso;
    protocolo.tituloDocumento = this.documento.titulo;
    protocolo.versao = versionamentoArquivo.versaoProtocolo;
    protocolo.caminhoArquivo = versionamentoArquivo.caminhoArquivo;

    console.log("this.documento documento.service l94 ", this.documento)
    console.log("-----Protocolo---Post")
    console.log(protocolo)
    return this.httpClient.post(`${API_BASE}/Minio/protocolo`, protocolo)
      .pipe(catchError(Error.error));
  }

  reservarProtocoloDeEntrega(numeroProcesso: string, usuario: UsuarioLogado): Observable<any> {
    const protocolo = new Protocolo(usuario.user.userName);
    protocolo.numeroProcesso = numeroProcesso;
    return this.httpClient.post<ResponseModel>(API_BASE + '/Protocolo/incluir', protocolo).pipe(
      catchError(Error.error)
    );
  }

  public gerarNumero(categoria: string, usuario: UsuarioLogado): Observable<any> {
    const protocolo = new Protocolo(usuario.user.userName);
    protocolo.chaveCategoria = categoria;
    return this.httpClient.post<ResponseModel>(API_BASE + '/Protocolo/incluir', protocolo).pipe(
      catchError(Error.error)
    );
  }
  //public gerarNumero(protocolo: Protocolo, usuario: UsuarioLogado): Observable<any> {
  // // const protocolo = new Protocolo(usuario.user.userName);
  //  //protocolo.chaveCategoria = categoria;
  //  return this.httpClient.post<ResponseModel>(API_BASE + '/Protocolo/incluir', protocolo).pipe(
  //    catchError(Error.error)
  //  );
  //}

  //public gerarComponenteDigital(): Observable<any> {
  //  return this.httpClient.post(this.fileService.getUploadPath(), this.documento)
  //    .pipe(catchError(Error.error));
  //}
  public gerarComponenteDigital(): Observable<any> {
    return this.httpClient.post(`${API_BASE}/Minio/Upload`, this.documento)
      .pipe(catchError(Error.error));
  }

  public listarCategorias(): Observable<any> {
    return this.httpClient.get(`${API_BASE}/categoria`).pipe(
      catchError(Error.error)
    );
  }

  public salvar(): Observable<any> {
    return this.httpClient.post<ResponseModel>(API_BASE + '/Documento/Incluir', this.documento).pipe(
      catchError(Error.error)
    );
  }

  public buscarListaDocumentosDoProcesso(id: string): Observable<any> {
    return this.httpClient.get<ResponseModel>(API_BASE + '/Documento/processo/' + id).pipe(
      catchError(Error.error)
    );
  }

  public buscarPorId(id: string): Observable<any> {
    return this.httpClient.get<ResponseModel>(API_BASE + '/Documento/' + id).pipe(
      catchError(Error.error)
    );
  }

  public leituraMinhasMensagens(id: string): Observable<any> {
    return this.httpClient.get<ResponseModel>(API_BASE + '/Documento/minhasMensagens/' + id).pipe(
      catchError(Error.error)
    );
  }

  public listarDocumentos(): Observable<any> {
    return this.httpClient.get<ResponseModel>(API_BASE + '/Documento/ListaDocumentos').pipe(
      catchError(Error.error)
    );
  }

  public listarDocumentosCoordenadoria(unidade: number, usuario: string, grupo: number): Observable<any> {
    return this.httpClient.get<ResponseModel>(API_BASE + `/Documento/coordenadoria/${unidade}/${usuario}/${grupo}`).pipe(
      catchError(Error.error)
    );
  }

  public listaDocProcessoCategoria(chaveCategoria: string, idProcesso: number): Observable<any> {
    return this.httpClient.get<ResponseModel>(API_BASE + `/Documento/categoria/${chaveCategoria}/${idProcesso}`).pipe(
      catchError(Error.error)
    );
  }

  private ie(value: string) {
    if (value) {
      const ie = value.toString().replace(/\D/g, '');
      return ie.substring(0, 2) + '.' + ie.substring(2, 5) + '.' + ie.substring(5, 8) + '-' + ie.substring(8);
    }
  }

  public regraPorCategoria(): boolean {
    switch (this.documento.chaveCategoria) {
      case 'CANCELAMENTO DE EVENTOS':
        if (!this.documento.detalhes.documentoCancelado) {
          this.authService.mensagemWObservable.next('É necessário selecionar o documento que será cancelado');
        }
        return !!this.documento.detalhes.documentoCancelado;
      default:
        return true;
    }
  }
}
