import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { BehaviorSubject, Observable } from 'rxjs';
import { Md5 } from 'ts-md5';
import { API_BASE } from '../app.api';
import { AuthService } from '../login/auth.service';
import { ComponenteDigital } from '../model/documentos.model';
import { VersionamentoArquivo } from '../model/versionamento-arquivo.model';
import { DocumentoService } from './documento.service';
import { PathService } from './path.service';
import { VersionamentoArquivosService } from './versionamento-arquivos.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private carregandoTab: boolean;
  carregaTabObservavel = new BehaviorSubject(this.carregandoTab);
  carregandoTab_ = this.carregaTabObservavel.asObservable();
  apiUpload = API_BASE + '/Minio';
  versionamentoArquivo = new VersionamentoArquivo()
  uploadCompleto: boolean;
  uploadCompletoObservavel = new BehaviorSubject(this.uploadCompleto);

  constructor(private authService: AuthService,
    private httpClient: HttpClient,
    private documentoService: DocumentoService,
    private pathService: PathService,
    private versionamentoArquivoService: VersionamentoArquivosService) {
    this.versionamentoArquivoService.getVersionamentoArquivos().subscribe(value => {
      this.versionamentoArquivo.caminhoArquivo = value.caminhoArquivo;
      this.versionamentoArquivo.versaoProtocolo = value.versaoProtocolo;
    });
  }

  base64ToBlob(fileBase64: any, name: string): Blob {
    const binary = atob(fileBase64);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'application/pdf' });
  }

  calcularTamanhoArquivo(tamanho: number): string {
    if (tamanho / 1024 < 1024) {
      return (tamanho / 1000).toPrecision(3).toString() + 'KB';
    } else {
      return (tamanho / 1000000).toPrecision(3).toString() + 'MB';
    }
  }


  enviarComponenteDigitalParaRepositorio(blob: Blob,
    componenteDigital: ComponenteDigital,
    tipo: number) {
    this.carregaTabObservavel.next(true);
    const uploading: FileUploader = new FileUploader({
      url: this.getUploadPath()
    });
    if (tipo === 1) {
      componenteDigital.nomeOriginal = this.documentoService.documento.titulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + '.pdf';
      console.log("teste enviarComponenteDigitalParaRepositorio: ", componenteDigital.nomeOriginal)
    } else if (tipo === 3) {
      componenteDigital.nomeOriginal = this.documentoService.documento.protocolo + '.pdf';
    }
    uploading.addToQueue(new Array<File>(new File([blob], componenteDigital.nomeOriginal)));
    uploading.onBeforeUploadItem = item => {
      item.headers = [
        //caminho do arquivo está sendo puxado nesse header escrito "processo"
        //{ name: 'protocolo', value: this.versionamentoArquivo.caminhoArquivo },
        //{ name: 'categoria', value: this.documentoService.documento.chaveCategoria }

        { name: 'processo', value: this.documentoService.documento.numeroProcesso },
        { name: 'categoria', value: this.documentoService.documento.chaveCategoria.normalize("NFD").replace(/[\u0300-\u036f]/g, "") },
        { name: 'protocolo', value: this.documentoService.documento.numero },
        { name: 'caminho', value: this.versionamentoArquivo.caminhoArquivo }
      ];
      item.withCredentials = false;
    };
    uploading.onCompleteItem = (
      item: any, response: any,
    ) => {
      console.log("baixeiArquivo")
      console.log(this.versionamentoArquivo)
      console.log(response);

      if (JSON.parse(response).status.code == 400) {
        this.authService.mensagemWObservable.next(JSON.parse(response).data);
        return false;
      }

      var retornoUpload = JSON.parse(response)
      componenteDigital.formato = 'application/pdf';
      componenteDigital.caminhoArquivo = retornoUpload.data.key;
      componenteDigital.quantidadePaginas = retornoUpload.data.info.quantidadePaginas;
      componenteDigital.chave = null;
      componenteDigital.versao = retornoUpload.data.version;

      componenteDigital.tamanho = this.calcularTamanhoArquivo(item.file.size);
      console.log(componenteDigital);
      if (tipo === 1) {
        this.documentoService.documento.componenteDigital = componenteDigital;
        this.uploadCompletoObservavel.next(true);
      } else if (tipo === 2) {
        this.documentoService.documento.anexos.push(componenteDigital);
      } else if (tipo === 3) {
        this.documentoService.documento.arquivoProtocolo = componenteDigital;
      }
      const myReader: FileReader = new FileReader();
      myReader.onloadend = e => {
        const base64 = myReader.result;
        componenteDigital.md5 = Md5.hashStr(base64).toString();
        this.carregaTabObservavel.next(false);
      };
      myReader.readAsDataURL(item.file.rawFile);
    };
    uploading.uploadAll();
  }

  getUploadPath(): string {
    switch (this.authService.profileObservable.getValue()) {
      case 'Development':
      case 'Homologation':
      case 'dev':
      case 'hom':
      case 'local':
        return `${this.apiUpload}/Upload`;
      case 'Production':
      case 'prd':
        return `${this.apiUpload}/Upload`;
      default:
        return null;
    }
  }


  //baixarArquivoPorChave(chave: string): Observable<any> {
  //  const getHeaders: HttpHeaders = new HttpHeaders({
  //    'accept': 'application/octet-stream'
  //  });
  //  const query: HttpParams = new HttpParams().set('keyFile', chave);
  //  return this.httpClient.get(`${this.pathService.getRepositorioPath('files')}`, {
  //    responseType: 'blob', headers: getHeaders, params: query
  //  }).pipe(catchError(Error.error));
  //}

  public baixarArquivoCaminho(caminho: string): Observable<any> {
    let params = new HttpParams().set("caminho", caminho);
    const getHeaders: HttpHeaders = new HttpHeaders({
      'accept': 'application/octet-stream'
    });
    return this.httpClient.get(`${API_BASE}/Minio/obterArquivo`, {
      responseType: 'blob', headers: getHeaders, params: params
    });
  }
}
